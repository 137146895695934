<template>
	<div class="review-write">
		<div class="review-write__title-wrapper">
			<h2 class="section-title">Напишите отзыв</h2>

			<!-- <tk-button
				class="review__reset-btn"
				kind="reset-btn"
				form="review-form"
				type="reset"
			>
				Отменить
			</tk-button> -->
		</div>

		<p class="section-subtitle">Оцените по критериям ниже поведение гостя</p>

    <form @submit.prevent="submit" action="#" class="review" id="review-form">
      <div
        v-for="(item, index) in config"
        :key="index"
        class="review__item review-item"
      >
        <h3 class="review-item__title">{{ item.title }}</h3>
        <div class="review-item__card">
          <p class="review-item__description">
            {{ item.description }}
          </p>
          <div class="review-item__area">
            <template v-for="itm of 5" :key="itm">
              <input
                type="radio"
                v-model="form[item.key]"
                :id="`${item.key}-${itm}`"
                :name="item.key"
                :value="itm"
              />
              <label :for="`${item.key}-${itm}`" :title="`Оценка «${6 - itm}»`" />
            </template>
          </div>
        </div>
      </div>

      <div class="review__item review-item">
        <h3 class="review-item__title">Напишите свое впечатление о госте</h3>
        <p class="review-item__subtitle">
          Здесь вы можете подробно рассказать о поведении гостя
        </p>
        <textarea
          v-model="payload.text"
          class="review-item__textarea"
          placeholder="Текст"
        />
      </div>
      <button
        class="btn review-item__submit-btn"
        :disabled="inProcess"
        @click="showReviewThanksModal"
      >
        Отправить
      </button>
    </form>

<!--		<form @submit.prevent="submit" class="review" id="review-form">
			<div class="review-item review-item__rules">
				<h3 class="review-item__title">Соблюдение правил дома</h3>
				<div class="review-item__card">
					<p class="review-item__description">
						Оцените насколько гость соблюдал правила дома
					</p>
					<div class="review-item__area">
						<input type="radio" id="rules-5" name="rules-rating" :value="5" />
						<label for="rules-5" title="Оценка «5»"></label>
						<input type="radio" id="rules-4" name="rules-rating" :value="4" />
						<label for="rules-4" title="Оценка «4»"></label>
						<input type="radio" id="rules-3" name="rules-rating" :value="3" />
						<label for="rules-3" title="Оценка «3»"></label>
						<input type="radio" id="rules-2" name="rules-rating" :value="2" />
						<label for="rules-2" title="Оценка «2»"></label>
						<input type="radio" id="rules-1" name="rules-rating" :value="1" />
						<label for="rules-1" title="Оценка «1»"></label>
					</div>
				</div>
			</div>
			<div class="review-item review-item__cleanliness">
				<h3 class="review-item__title">Соблюдение чистоты в жилье</h3>
				<div class="review-item__card">
					<p class="review-item__description">
						Оцените насколько гость придерживался чистоты в помещении
					</p>
					<div class="review-item__area">
						<input
							type="radio"
							id="cleanliness-5"
							name="cleanliness-rating"
							value="5"
						/>
						<label for="cleanliness-5" title="Оценка «5»"></label>
						<input
							type="radio"
							id="cleanliness-4"
							name="cleanliness-rating"
							value="4"
						/>
						<label for="cleanliness-4" title="Оценка «4»"></label>
						<input
							type="radio"
							id="cleanliness-3"
							name="cleanliness-rating"
							value="3"
						/>
						<label for="cleanliness-3" title="Оценка «3»"></label>
						<input
							type="radio"
							id="cleanliness-2"
							name="cleanliness-rating"
							value="2"
						/>
						<label for="cleanliness-2" title="Оценка «2»"></label>
						<input
							type="radio"
							id="cleanliness-1"
							name="cleanliness-rating"
							value="1"
						/>
						<label for="cleanliness-1" title="Оценка «1»"></label>
					</div>
				</div>
			</div>
			<div class="review-item review-item__communication">
				<h3 class="review-item__title">Общительность</h3>
				<div class="review-item__card">
					<p class="review-item__description">
						Оцените насколько гость был общителен,не был ли он напорист и
						проявлял ли вежливость
					</p>
					<div class="review-item__area">
						<input
							type="radio"
							id="communication-5"
							name="communication-rating"
							value="5"
						/>
						<label for="communication-5" title="Оценка «5»"></label>
						<input
							type="radio"
							id="communication-4"
							name="communication-rating"
							value="4"
						/>
						<label for="communication-4" title="Оценка «4»"></label>
						<input
							type="radio"
							id="communication-3"
							name="communication-rating"
							value="3"
						/>
						<label for="communication-3" title="Оценка «3»"></label>
						<input
							type="radio"
							id="communication-2"
							name="communication-rating"
							value="2"
						/>
						<label for="communication-2" title="Оценка «2»"></label>
						<input
							type="radio"
							id="communication-1"
							name="communication-rating"
							value="1"
						/>
						<label for="communication-1" title="Оценка «1»"></label>
					</div>
				</div>
			</div>

			<div class="review__item review-item">
				<h3 class="review-item__title">Напишите свое впечатление о госте</h3>
				<p class="review-item__subtitle">
					Здесь вы можете подробно рассказать о поведении гостя
				</p>
				<textarea
					v-model="payload.text"
					class="review-item__textarea"
					placeholder="Текст"
				/>
			</div>

			<button
				class="btn review-item__submit-btn"
				type="submit"
				@click="showReviewThanksModalLandlord"
			>
				Отправить
			</button>
		</form>-->
	</div>
</template>

<script>
const config = [
	{
		title: 'Соблюдение правил дома',
		description: 'Оцените насколько гость соблюдал правила дома',
		key: 101
	},
	{
		title: 'Общительность',
		description: 'Оцените насколько гость был общителен,не был ли он напорист и проявлял ли вежливость',
		key: 102
	},
	{
		title: 'Соблюдение чистоты в жилье',
		description:
			'Оцените насколько гость придерживался чистоты в помещении',
		key: 103
	}
]
const fields = {
	101: 'Соблюдение правил дома',
	102: 'Общительность',
	103: 'Соблюдение чистоты в жилье'
}

export default {
	data() {
		return {
			form: {
				101: 0,
				102: 0,
				103: 0
			},
			payload: {
				id: this.$route.params.id,
				text: '',
				ratings: [
					{
						value: 1,
						target: 101
					},
					{
						value: 2,
						target: 102
					},
					{
						value: 4,
						target: 103
					}
				]
			},
      inProcess: false,
		}
	},
	computed: {
		config() {
			return config
		},
		fields() {
			return fields
		}
	},
	methods: {
		async submit() {
      this.inProcess = true

      this.payload.ratings = this.payload.ratings.map((itm) => ({
        ...itm,
        value: (6 - this.form[itm.target]) // какая-то магия, ибо this.form[itm.target] - кол-во пустых звездочек
      }))

      const response = await this.$api.reviews.setReviewLandlordToGuest({ ...this.payload })

      this.inProcess = false
      if (response && response.status) {
        this.$store.commit('showNotification', { type: 'success', text: 'Отзыв успешно отправлен' })
        this.$store.commit('showModal', {
          name: 'CommonInfo',
          props: {
            text: `Спасибо за оставленный отзыв! </br> Ваши оценки повлияют на рейтинг гостя.`
          }
        })

        this.$router.go(-1)
      } else {
        this.$store.commit('showNotification', { type: 'error', text: 'Все поля обязательны для заполнения' })
      }
		}
	}
}
</script>
